/** @jsx jsx */
import { Fragment } from "react";
import { jsx } from "theme-ui";
import Layout from "../components/layout";
import Faq from "../sections/homepage/faq";
import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";

import Cookies from "js-cookie";

const PRICING = {
  tiers: [
    {
      title: "Basic",
      price: 5,
      frequency: "/month",
      credits: "10,000",
      threads: 1,
      features: ["Best Effort SLA", "Basic support (response within 24 hours)"],
      cta: "Start Free Trial",
      mostPopular: false,
    },
    {
      title: "Hobby",
      price: 10,
      frequency: "/month",
      credits: "25,000",
      threads: 1,
      features: ["Best Effort SLA", "Basic support (response within 24 hours)"],
      cta: "Start Free Trial",
      mostPopular: false,
    },
    {
      title: "Bootstrap",
      price: 29,
      frequency: "/month",
      credits: "250k",
      threads: 10,
      features: ["Best Effort SLA", "Basic support (response within 24 hours)"],
      cta: "Start Free Trial",
      mostPopular: false,
    },
    {
      title: "Startup",
      price: 99,
      frequency: "/month",
      credits: "1M",
      threads: 25,
      features: [
        "Guaranteed SLA",
        "Priority Support (response within 8 hours including weekends)",
      ],
      cta: "Start Free Trial",
      mostPopular: true,
    },
    {
      title: "Business",
      price: 249,
      frequency: "/month",
      credits: "3M",
      threads: 50,
      features: [
        "Guaranteed SLA",
        "Priority Support (response within 8 hours including weekends)",
      ],
      cta: "Start Free Trial",
      mostPopular: true,
    },
  ],
};

const Pricing = () => {
  return (
    <Layout title="Pricing - ScrapeOwl">
      <section sx={styles.container}>
        <div sx={styles.intro}>
          <h1>Pricing</h1>
          <p>
            <span sx={{ color: "#777DF2", fontWeight: 600 }}>
              Get 1000 credits for free,
            </span>{" "}
            no credit card required.
          </p>
          <p>All features available on all plans.</p>
        </div>

        <div sx={styles.pricingWrapper}>
          {PRICING.tiers.map((tier) => {
            return (
              <Fragment>
                {Cookies.get("zimmwriter") &&
                  ["Basic", "Hobby"].includes(tier.title) && (
                    <div sx={styles.tier}>
                      <div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2 sx={styles.title}>{tier.title}</h2>
                        <div sx={styles.priceWrapper}>
                          <span sx={styles.price}>${tier.price}</span>
                          <span sx={styles.priceFrequency}>
                            {tier.frequency}
                          </span>
                        </div>
                      </div>

                      <div
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,

                          span: {
                            width: "45%",
                            textAlign: "center",
                            bg: "#F0F0FD",
                            fontWeight: 600,
                            py: 2,
                            borderRadius: "8px",
                            color: "purplegray",
                          },
                        }}
                      >
                        <span>{tier.credits} credits</span>
                        <span>{tier.threads} threads</span>
                      </div>

                      <ul sx={styles.featureList}>
                        {tier.features.map((feature) => {
                          return (
                            <li key={feature}>
                              <span>
                                <CheckIcon />
                              </span>
                              <span>{feature}</span>
                            </li>
                          );
                        })}
                      </ul>
                      <a
                        sx={styles.cta}
                        className={tier.mostPopular ? "isMostPopular" : null}
                        href="http://app.scrapeowl.com/register"
                      >
                        {tier.cta}
                      </a>
                    </div>
                  )}

                {Cookies.get("aimasher") &&
                  ["Basic", "Hobby"].includes(tier.title) && (
                    <div sx={styles.tier}>
                      <div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2 sx={styles.title}>{tier.title}</h2>
                        <div sx={styles.priceWrapper}>
                          <span sx={styles.price}>${tier.price}</span>
                          <span sx={styles.priceFrequency}>
                            {tier.frequency}
                          </span>
                        </div>
                      </div>

                      <div
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,

                          span: {
                            width: "45%",
                            textAlign: "center",
                            bg: "#F0F0FD",
                            fontWeight: 600,
                            py: 2,
                            borderRadius: "8px",
                            color: "purplegray",
                          },
                        }}
                      >
                        <span>{tier.credits} credits</span>
                        <span>{tier.threads} threads</span>
                      </div>

                      <ul sx={styles.featureList}>
                        {tier.features.map((feature) => {
                          return (
                            <li key={feature}>
                              <span>
                                <CheckIcon />
                              </span>
                              <span>{feature}</span>
                            </li>
                          );
                        })}
                      </ul>
                      <a
                        sx={styles.cta}
                        className={tier.mostPopular ? "isMostPopular" : null}
                        href="http://app.scrapeowl.com/register"
                      >
                        {tier.cta}
                      </a>
                    </div>
                  )}

                {Cookies.get("seomastersuite") &&
                  ["Basic", "Hobby"].includes(tier.title) && (
                    <div sx={styles.tier}>
                      <div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2 sx={styles.title}>{tier.title}</h2>
                        <div sx={styles.priceWrapper}>
                          <span sx={styles.price}>${tier.price}</span>
                          <span sx={styles.priceFrequency}>
                            {tier.frequency}
                          </span>
                        </div>
                      </div>

                      <div
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,

                          span: {
                            width: "45%",
                            textAlign: "center",
                            bg: "#F0F0FD",
                            fontWeight: 600,
                            py: 2,
                            borderRadius: "8px",
                            color: "purplegray",
                          },
                        }}
                      >
                        <span>{tier.credits} credits</span>
                        <span>{tier.threads} threads</span>
                      </div>

                      <ul sx={styles.featureList}>
                        {tier.features.map((feature) => {
                          return (
                            <li key={feature}>
                              <span>
                                <CheckIcon />
                              </span>
                              <span>{feature}</span>
                            </li>
                          );
                        })}
                      </ul>
                      <a
                        sx={styles.cta}
                        className={tier.mostPopular ? "isMostPopular" : null}
                        href="http://app.scrapeowl.com/register"
                      >
                        {tier.cta}
                      </a>
                    </div>
                  )}

                {!["Basic", "Hobby"].includes(tier.title) && (
                  <div sx={styles.tier}>
                    <div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2 sx={styles.title}>{tier.title}</h2>
                      <div sx={styles.priceWrapper}>
                        <span sx={styles.price}>${tier.price}</span>
                        <span sx={styles.priceFrequency}>{tier.frequency}</span>
                      </div>
                    </div>

                    <div
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 3,

                        span: {
                          width: "45%",
                          textAlign: "center",
                          bg: "#F0F0FD",
                          fontWeight: 600,
                          py: 2,
                          borderRadius: "8px",
                          color: "purplegray",
                        },
                      }}
                    >
                      <span>{tier.credits} credits</span>
                      <span>{tier.threads} threads</span>
                    </div>

                    <ul sx={styles.featureList}>
                      {tier.features.map((feature) => {
                        return (
                          <li key={feature}>
                            <span>
                              <CheckIcon />
                            </span>
                            <span>{feature}</span>
                          </li>
                        );
                      })}
                    </ul>
                    <a
                      sx={styles.cta}
                      className={tier.mostPopular ? "isMostPopular" : null}
                      href="http://app.scrapeowl.com/register"
                    >
                      {tier.cta}
                    </a>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>

        <div
          sx={{
            mt: [4, 6],
            fontWeight: "bold",
            fontSize: [4, 5],
            textAlign: "center",
          }}
        >
          <p sx={{ color: "#C5C5D0" }}>You Need more threads or credits?</p>
          <p>
            <a sx={{ color: "#777DF2" }} href="mailto:team@scrapeowl.com">
              Email us
            </a>
          </p>
        </div>
      </section>
      <Faq />
    </Layout>
  );
};

export default Pricing;

const styles = {
  container: {
    variant: "layout.container",
    pt: 6,
    pb: 6,
  },

  intro: {
    textAlign: "center",

    h1: {
      fontSize: [4, 5, 6],
      color: "purplegray",
    },

    "p:first-of-type": {
      mb: 5,
    },

    "p:nth-of-type(2)": {
      mb: 2,
    },

    p: {
      fontSize: [2, 3],
      color: "rgba(107, 114, 128, 1)",
    },
  },

  pricingWrapper: {
    display: "flex",
    flexDirection: ["column", "row"],
    justifyContent: "space-between",
    flexWrap: "wrap",
    mb: 5,
  },

  tier: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flex: "1 1 23rem",
    m: 2,
    mt: [3, 4, 4],
    borderRadius: "1rem",
    border: "1px solid #e5e7eb",
    p: [3, 4],
    color: "rgba(107, 114, 128, 1)",
    boxShadow: "0px 0px 8px 1px rgba(0, 0, 0, 0.08)",
  },

  featureList: {
    mt: 3,

    li: {
      display: "flex",
      mt: 3,

      "span:not(:first-child)": {
        ml: 2,
        wordBreak: "break-word",
      },
    },
  },

  title: {
    color: "purplegray",
    fontSize: [3, 4],
  },

  description: {
    mt: 3,
  },

  priceWrapper: {
    color: "purplegray",
    fontWeight: "bold",
  },

  price: {
    fontSize: [3, 4, 4],
    color: "#777DF2",
  },

  priceFrequency: {
    fontSize: [2, 1, 1],
    fontWeight: 400,
  },

  cta: {
    textAlign: "center",
    fontSize: [2],
    mt: 4,
    py: ".75rem",
    px: 3,
    borderRadius: "8px",
    fontWeight: 600,
    color: "purple",
    bg: "#F0F0FD",
    transition: "background 0.2s linear",

    "&:hover": {
      bg: "rgba(224, 231, 255, 1)",
    },

    "&.isMostPopular": {
      bg: "#777DF2",
      color: "white",
    },

    "&.isMostPopular:hover": {
      bg: "#6366c2",
    },
  },
};
